body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');



.container {
  margin: 0 auto;
  max-width: 900px;
}

.rowC{
  display:flex; 
  flex-direction:row;
  justify-content: space-between;
  padding-top: 20px;
  padding-right: 40px;
  padding-bottom: 20px;
  padding-left: 40px;;
}


.modal {
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  left: 0;
  right: 0;
  top: 85%;
  border: 0px solid grey;
  position: fixed;
}